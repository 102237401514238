import { AzIconAuto, AzIconEquipment, AzIconMedicine, AzIconRenew, AzIconTires } from "@Development/ui-kit";

export const NAV_LINKS = (props) => ([
  {
    text: "Выбрать поставщика",
    link: `${props.gatsby}/marketplace`,
  },
  {
    text: "Партнерам",
    link: `${props.gatsby}/partners`,
  },
  {
    text: "Инвесторам",
    link: `${props.gatsby}/about#investors`,
  },
  {
    text: "Частые вопросы",
    link: `${props.gatsby}/faqs`,
  },
  {
    text: "О нас",
    link: `${props.gatsby}/about`,
  }
]);

export const NAV_LINKS_EXTENDED_LINE = (props) => ([
  {
    text: "Аренза.Оборудование",
    link: props.gatsby,
    icon: AzIconEquipment
  },
  {
    text: "Аренза.Авто",
    link: `${props.gatsby}/lizing-auto`,
    icon: AzIconAuto
  },
  {
    text: "Аренза.Медицина",
    link: `${props.gatsby}/lizing-meditsinskogo-oborudovaniya`,
    icon: AzIconMedicine
  },
  {
    text: "Аренза.Шины",
    link: `${props.gatsby}/shiny-v-lizing`,
    icon: AzIconTires
  },
  {
    text: "Аренза.Renew",
    link: props.shop,
    icon: AzIconRenew
  }
]);