import PropTypes from "prop-types";
import React from "react";

import { Box } from "@material-ui/core";

import AppBar from "../components/AppBar";

LayoutNavigation.propTypes = {
  children: PropTypes.node,
};
export default function LayoutNavigation({ children }) {
  return (
    <>
      <AppBar />
      <Box
        sx={{
          minHeight: theme => theme.mixins.toolbar /* Компенсация высоты appbar */,
        }}
      />
      {children}
    </>
  );
}
